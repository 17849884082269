<template>
  <div class="flex flex-col flex-grow pt-24">
    <div class="flex flex-col px-10">
      <vector
        :src="assets.icons.sm.checkmarkCircle"
        class="flex justify-center mb-10"
      />
      <div class="text-3xl font-bold mb-4">Congrats!</div>
      <div class="text-gray-700 mb-20">
        Your credit card activation was successful.
      </div>
    </div>
    <div class="w-full bg-blue-200" style="height: 2px">
      <div
        class="bg-blue-500 h-full"
        :style="{ width: progressWidth }"
      />
    </div>
    <div class="grid flex-grow place-items-center py-8">
      <button
        type="button"
        class="btn btn-blue btn-md"
        @click.prevent="emitRequestCompletion"
      >
        {{ inDashboard ? 'Okay' : 'Go To Dashboard' }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  data() {
    return {
      progress: 75,
    };
  },
  computed: {
    inDashboard() {
      return this.$route.name === 'dashboard';
    },
    progressWidth() {
      return `${this.progress}%`;
    },
  },
  mounted() {
    this.$emit('mounted');
    this.increaseProgress();
  },
  methods: {
    emitRequestCompletion() {
      this.$emit('request-card');
    },
    increaseProgress() {
      setTimeout(() => {
        this.progress = 100;
      }, 10);
    },
  },
};
</script>
